<template>
  <div id="login">
        <h3 class="mt-5 mb-5">ENTRAR O REGISTRARTE</h3>
      <form  class="text-center" @submit.prevent="login()">
          <input type="text" class="text-box" placeholder="usuario" required v-model="user.email">
          <input type="password" class="text-box" placeholder="clave" required v-model="user.password">
          <button type="submit" class="btn bg-primary text-light" style="width:80%">Iniciar Sesión</button>
      </form>
        <a style="text-decoration: none; color:black" href="https://docs.google.com/forms/d/1iRqZdp1v4Unm8vChFh_TKtQ4gERvZo75Vn-G8b0JSsM/viewform?edit_requested=true" target="_blank"><span class="btn mt-3" style="width:80%;background:rgba(255,255,255,0.4); border: solid 1px #fff" >Registrate!</span></a><br>
        <span @click.prevent="$bvModal.show('modal-recoverPassword')" class="mt-3 pointer">Olvidé mi contraseña!</span>
        <div v-if="registro">
          <span>Haz click <a  href="mailto:correo@gmail.com">aqui </a> para Enviar tus datos, te contactaremos en breve!.</span>
        </div>
      <!-- modal restablecer contraseña -->
    <b-modal id="modal-recoverPassword" hide-footer centered >
      <template #modal-title>
        ¿Olvidaste tu contraseña?
      </template>

      <form class="container" method="POST" @submit.prevent="recoverPassword()">
        <span>Ingresa tu correo electrónico para restablecerla.</span>
        <div class="col-md-12 mt-3 text-center">
          <input  type="email" name="email"
          class="form-control col-12" placeholder="Ingresa tu Email" required v-model="email"
          pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}"
          >
          <button type="submit" class="btn bg-primary text-light mt-3" style="width:50%">Restablecer contraseña</button>
        </div>
      </form>
    </b-modal>
  </div>

</template>

<script>
export default {
  data: () => ({
    user: {},
    registro: false,
    email: ''
  }),
  mounted(){
    if(this.$store.state.user!=null){
      this.$router.push({name: 'Checkout'});
    }
  },
  methods: {
    async recoverPassword(){
      if (this.email ==''){
        this.$swal({
          icon: 'error',
          title: 'Oopss!',
          text: 'Debes ingresar tu correo electrónico.'
        });
        return
      }
      const data ={
        mail: this.email
      }
      const recoverPass = await this.$store.dispatch("saveNew", {
        table: "recoverPassword",
        data
      });
      this.$bvModal.hide('modal-recoverPassword');
      this.email = ''
      this.$swal({
        icon: 'success',
        title: 'Muy bien!',
        text: 'En breve te enviaremos tu nueva contraseña!'
      });
    },
    async login() {
      // this.$router.push({ name: "Checkout" });
      try {
        const user = await this.$store.dispatch("saveNew", {
          table: "customersLogin",
          data: this.user,
        });
        console.log('user in login ', user)
        if(user.error){
          this.$swal({
            icon: 'error',
            title: 'Oopss!',
            text: 'Error verificar credenciales.' // + user,
          });
        }
        else {

          if (user.status == 1) {
            localStorage.setItem("user", JSON.stringify(user));
            this.$store.commit("setUser", user);
            this.$router.push({ name: "Checkout" });
            this.$store.commit("refreshLogoutButton", 1)
            //alert("Error al iniciar sesión");
          }
        }
      } catch (error) {
          this.$swal({
            icon: "error",
            title: "Error!",
            text: error,
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../app.scss";

#login {
  margin-top:-100px;
  padding:2rem;
  width: auto;
  height: auto;
  left: 0;
  right: 0;
  bottom: 0;
text-align: center;
  background-color: #000;
  background: url("https://i.pinimg.com/originals/71/63/5c/71635ca34c4a285e07a019de95565374.png");
  background-size: cover;
}

#login form {
  margin: 100px auto;
  max-width: 450px;
  padding: 2rem;
  background-color: rgba($color: #ddd, $alpha: 0.3);
  border-radius: 8px;
  outline: rgba($color: #fff, $alpha: 0.7);
  outline-width: 10px;

  color: #fff;
}

.text-box {
  border: solid 1px #ddd;
  background-color: rgba($color: #000, $alpha: 0.3);
  padding: 4px;
  text-align: center;
  width: 80%;
  margin: 1rem;
  color: #fff;
}

.text-box::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 0.8; /* Firefox */
}
</style>
